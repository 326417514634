<script>
  import SocialIcons from "@src/views/SocialIcons.svelte";
</script>

<footer>
  <div class="content">
    <span>Osasto 41 &copy; {new Date().getFullYear()}</span>
    <SocialIcons />
  </div>
</footer>

<style>
  footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: var(--footer-height);
    background: #000;
    border-top: 1px solid #000;
  }

  .content {
    display: flex;
    align-items: center;
    max-width: var(--content-width);
    margin: 0 auto;
    height: 100%;
    justify-content: center;
    color: var(--text-color);
  }
</style>
