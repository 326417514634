<script lang="ts">
  import Button from "@src/components/Button.svelte";
  import Skeleton from "@src/components/Skeleton.svelte";
  import { newsStore, getNews, fetchOlderNewsArticles } from "@src/store/news";
  import { errorToast } from "@src/utils/toast";
  import NewsArticleCard from "./NewsArticleCard.svelte";

  let olderArticlesPromise = Promise.resolve();

  $: lastArticleCreated =
    $newsStore?.articles[$newsStore.articles.length - 1]?.created;
</script>

<style>
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    min-height: 15rem;
    max-width: 40rem;
    width: 100%;
  }

  .initial-card {
    display: flex;
    width: 100%;
    min-height: 15rem;
  }
</style>

<div class="wrapper">
  {#await getNews().catch(errorToast('Virhe haettaessa uutisia. Yritä ladata sivu uudestaan.'))}
    <div class="initial-card">
      <Skeleton />
    </div>
  {:then}
    {#if $newsStore.articles.length}
      {#each $newsStore.articles as article}
        <NewsArticleCard {article} />
      {/each}
      {#if $newsStore.hasMore}
        {#await olderArticlesPromise.catch() then _}
          <p>
            <Button
              label="Hae lisää"
              variant="secondary"
              on:click={() => {
                olderArticlesPromise = fetchOlderNewsArticles({
                  before: lastArticleCreated,
                });
              }} />
          </p>
        {/await}
      {/if}
    {:else}
      <NewsArticleCard article={null} />
    {/if}
  {:catch}
    <Skeleton state="error" />
  {/await}
  {#await olderArticlesPromise.catch(errorToast('Virhe haettaessa uutisia. Yritä ladata sivu uudestaan.'))}
    <div class="initial-card">
      <Skeleton />
    </div>
  {:catch}
    <div class="initial-card">
      <Skeleton state="error" />
    </div>
  {/await}
</div>
