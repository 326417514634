<script lang="ts">
  export let state: "open" | "close" = "open";
</script>

<style>
  svg {
    cursor: pointer;
    height: 100%;
  }

  svg > * {
    transition: transform 0.2s ease, opacity 0.2s ease;
  }

  #bottom {
    transform-origin: bottom left;
  }

  .close #bottom {
    transform: translate(20%) rotate(-45deg);
  }

  .close #center {
    opacity: 0;
    transform: translateX(100%);
  }

  #top {
    transform-origin: top left;
  }

  .close #top {
    transform: translate(20%) rotate(45deg);
  }
</style>

<svg class={state} viewBox="0 0 100 80" width="40" height="40">
  <rect id="top" width="90" height="15" />
  <rect id="center" y="30" width="90" height="15" />
  <rect id="bottom" y="60" width="90" height="15" />
</svg>
