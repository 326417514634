<script lang="ts">
  import { Route, Router } from "svelte-routing";
  import Footer from "./components/Footer.svelte";
  import NavBar from "./components/NavBar.svelte";
  import ToastQueue from "./components/ToastQueue.svelte";
  import HomePage from "./views/HomePage.svelte";
  import MembershipApplicationPage from "./views/MembershipApplicationPage.svelte";
  import MembershipBenefitsPage from "./views/MembershipBenefitsPage.svelte";
  import NotFoundPage from "./views/NotFoundPage.svelte";
  import TripPage from "./views/TripPage.svelte";
  import TripRulesPage from "./views/TripRulesPage.svelte";
  import TripsPage from "./views/TripsPage.svelte";

  export let url = "";

  const navbarItems = [
    { to: "matkat", label: "Matkat" },
    { to: "matkasaannot", label: "Matkasäännöt" },
    { to: "jaseneksi", label: "Jäseneksi" },
    { to: "jasenedut", label: "Jäsenedut" },
    {
      to: "https://kisakauppa.fi/kauppa/osasto41/",
      label: "Kauppa",
      isExternal: true,
    },
  ];
</script>

<Router basepath="/" {url}>
  <NavBar items={navbarItems} />
  <div id="page">
    <div id="content">
      <Route path="matkat" component={TripsPage} />
      <Route path="matkat/:slug" let:params>
        <TripPage slug={params.slug} />
      </Route>
      <Route path="matkasaannot" component={TripRulesPage} />
      <!-- <Route path="tuotteet" component={MerchandisePage} /> -->
      <Route path="jaseneksi" component={MembershipApplicationPage} />
      <Route path="jasenedut" component={MembershipBenefitsPage} />
      <!-- <Route path="tietoa" component={AboutPage} /> -->
      <Route path="/" component={HomePage} />
    </div>
    <Footer />
    <ToastQueue />
  </div>
  <Route component={NotFoundPage} />
</Router>

<style>
  #page {
    position: relative;
    min-height: 100%;
    display: flex;
  }

  #content {
    display: flex;
    flex-direction: column;
    padding-top: var(--navbar-height);
    padding-bottom: var(--footer-height);
    min-height: 100%;
    width: 100%;
    box-sizing: border-box;
  }
</style>
