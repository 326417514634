<script lang="ts">
  import { fade } from "svelte/transition";
  import type { NewsArticle } from "@src/store/news";
  import { format } from "date-fns";

  export let article: NewsArticle = null;

  $: createdDate = !article ? null : format(article.created, "dd.MM.yyyy");
  $: createdTime = !article ? null : format(article.created, "HH:mm");
</script>

<style>
  .card {
    display: flex;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.3);
    min-height: 15rem;
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
    margin-bottom: 3rem;
  }

  .heading {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }

  .title {
    flex-grow: 1;
    font-size: 1.5rem;
    font-weight: bold;
  }

  .created {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: 1rem;
  }

  .created .date {
    font-size: 1.2rem;
    margin-bottom: 0.2rem;
  }

  .created .time {
    font-size: 0.8rem;
  }

  .body {
    line-height: 1.6;
  }

  .card:last-child {
    margin-bottom: 0;
  }

  .empty {
    text-align: center;
  }
</style>

<div class="card" in:fade>
  {#if article}
    <div class="heading">
      <div class="title">{article.title}</div>
      <div class="created">
        <div class="date">{createdDate}</div>
        <div class="time">klo {createdTime}</div>
      </div>
    </div>
    <div class="body">
      {@html article.body}
    </div>
  {:else}
    <h2 class="empty">Ei julkaistuja uutisia</h2>
  {/if}
</div>
