<script lang="ts">
  import { onMount } from "svelte";

  import Toast from "./Toast.svelte";
  import type { ToastItem } from "@src/utils/toast";

  /**
   * How long will each toast be displayed (in milliseconds)?
   */
  export const retainTime = 5000;

  /**
   * How many toasts are allowed to be visible at once?
   * If there are more toasts pushed, they will show when previous toasts get removed.
   */
  export const maxQueueLength = 5;

  let toasts: ToastItem[] = [];
  let idSequence = 0;

  onMount(() => {
    (<any>window).pushToast = (toast: ToastItem) => {
      toast.id = idSequence++;
      toasts = [toast, ...toasts];
    };
  });

  $: visibleToasts = toasts.slice(-maxQueueLength);
  $: hiddenToasts = toasts.slice(0, -maxQueueLength);

  // Set timeout for closing each toast that comes into view
  $: visibleToasts.forEach((toast) => {
    if (!toast.closeTimeout) {
      toast.closeTimeout = setTimeout(() => {
        closeToast(toast.id);
      }, retainTime);
    }
  });

  // If a toast gets pushed out of view, clear the timeout until it gets visible again
  $: hiddenToasts.forEach((toast) => {
    if (toast.closeTimeout) {
      clearTimeout(toast.closeTimeout);
      toast.closeTimeout = null;
    }
  });

  function closeToast(id: number) {
    toasts = toasts.filter((toast) => toast.id !== id);
  }
</script>

<div class="wrapper" class:fade-top={toasts.length >= maxQueueLength}>
  {#each visibleToasts as { id, level, message } (id)}
    <Toast {level} {message} on:close={() => closeToast(Number(id))} />
  {/each}
</div>

<style>
  .wrapper {
    position: fixed;
    bottom: 1em;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    max-height: 20em;
  }

  .fade-top {
    mask-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 1) 1.5em
    );
  }
</style>
