<script lang="ts">
  import InputLabel from "./InputLabel.svelte";

  export let placeholder = "";
  export let value: string | number;
  export let type = "text";
  export let required = false;
  export let disabled = false;
  export let maxlength: number = undefined;
  export let id = `input-${Math.random().toString(36).substring(2)}`;
  export let label = "";

  let touched = false;
</script>

<InputLabel {id} {required}>{label}</InputLabel>
<!-- "type" attribute cannot be dynamic when input has two-way binding -->
{#if type === "email"}
  <input
    class:touched
    type="email"
    {id}
    {placeholder}
    bind:value
    {required}
    {disabled}
    {maxlength}
    on:blur={() => {
      touched = true;
    }}
  />
{:else if type === "number"}
  <input
    class:touched
    type="number"
    {id}
    {placeholder}
    bind:value
    pattern="\d*"
    {required}
    {disabled}
    {maxlength}
    on:blur={() => {
      touched = true;
    }}
  />
{:else}
  <input
    class:touched
    type="text"
    {id}
    {placeholder}
    bind:value
    {required}
    {disabled}
    {maxlength}
    on:blur={() => {
      touched = true;
    }}
  />
{/if}

<style>
  input.touched:invalid {
    background: #fcc;
  }

  input {
    margin-bottom: 0.5rem;
  }
</style>
