<script lang="ts">
  import Chip from "@src/components/Chip.svelte";
  import PageWrapper from "@src/components/PageWrapper.svelte";
  import Row from "@src/components/Row.svelte";
  import Skeleton from "@src/components/Skeleton.svelte";
  import { getPublicTrips, getTripTitle, publicTrips } from "@src/store/trips";
  import { errorToast } from "@src/utils/toast";
  import { format } from "date-fns";
  import { navigate } from "svelte-routing";
</script>

<svelte:head>
  <title>Osasto 41 - Matkat</title>
</svelte:head>
<PageWrapper fillerColor="gray">
  <Row color="green">
    <h1>Matkat kaudella 2024-2025</h1>
    <div class="trips">
      {#await getPublicTrips().catch(errorToast("Virhe haettaessa matkoja. Ole hyvä ja yritä ladata sivu uudestaan."))}
        <Skeleton />
      {:then}
        <table>
          {#each $publicTrips as trip}
            <tr
              class={[
                trip.status === "past" ? "past" : null,
                trip.status === "cancelled" ? "cancelled" : null,
                trip.status === "published" ? "clickable" : null,
              ]
                .filter(Boolean)
                .join(" ")}
              on:click={() => {
                if (trip.status !== "published") {
                  return;
                }
                navigate(`/matkat/${trip.slug}`);
              }}
            >
              <td class="date"
                >{!trip.date ? "" : format(trip.date, "dd.MM.yyyy")}</td
              >
              <td>{getTripTitle(trip)}</td>
              <td
                class={[
                  "trip-status",
                  trip.status === "cancelled"
                    ? "cancelled"
                    : trip.status !== "published"
                      ? "not-published"
                      : trip.signupStatus === "sold_out"
                        ? "sold-out"
                        : trip.signupStatus === "closed"
                          ? "closed"
                          : trip.signupStatus === "open"
                            ? "open"
                            : null,
                ]
                  .filter(Boolean)
                  .join(" ")}
              >
                {#if trip.status !== "past"}
                  <Chip>
                    {trip.status === "cancelled"
                      ? "Peruttu"
                      : trip.status !== "published"
                        ? "Tulossa"
                        : trip.signupStatus === "sold_out"
                          ? "Loppuunmyyty"
                          : trip.signupStatus === "closed"
                            ? "Kiinni"
                            : trip.signupStatus === "open"
                              ? "Auki"
                              : ""}
                  </Chip>
                {/if}
              </td>
            </tr>
          {/each}
        </table>
      {:catch}
        <Skeleton state="error" />
      {/await}
    </div>
  </Row>
  <Row color="gray" filler />
</PageWrapper>

<style>
  .trips {
    min-height: 20rem;
    width: 100%;
    max-width: 40rem;
  }

  table {
    width: 100%;
    max-width: 45rem;
    border-collapse: collapse;
    margin-top: 2rem;
  }

  td {
    padding: 1rem;
  }

  td.date {
    width: 0;
    font-size: 0.8rem;
  }

  tr.past {
    font-style: italic;
    color: #ccc;
  }

  tr.cancelled > td:not(.trip-status) {
    text-decoration: line-through;
    font-style: italic;
    color: #ccc;
  }

  tr.clickable {
    background: rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: background 0.2s linear;
  }

  tr.clickable:hover,
  tr.clickable:active {
    background: rgba(255, 255, 255, 0.1);
  }

  tr:nth-child(even) {
    background: rgba(0, 0, 0, 0.2);
  }

  td.trip-status {
    text-align: right;
  }

  td.trip-status.open :global {
    --chip-background-color: green;
    --chip-text-color: #fff;
  }

  td.trip-status.cancelled :global {
    --chip-background-color: #000;
    --chip-text-color: var(--white);
  }

  td.trip-status.closed :global {
    --chip-background-color: #000;
    --chip-text-color: var(--white);
  }

  td.trip-status.sold-out :global {
    --chip-background-color: #000;
    --chip-text-color: var(--white);
  }

  td.trip-status.not-published :global {
    --chip-background-color: #666;
    --chip-text-color: #fff;
  }
</style>
