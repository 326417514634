import { getRandomItem } from "./random";
export const teamNameMap = {
    assat: "Ässät",
    hifk: "HIFK",
    hpk: "HPK",
    ilves: "Ilves",
    jukurit: "Jukurit",
    jyp: "JYP",
    kalpa: "KalPa",
    karpat: "Kärpät",
    "kiekko-espoo": "Kiekko-Espoo",
    kookoo: "KooKoo",
    lukko: "Lukko",
    pelicans: "Pelicans",
    saipa: "SaiPa",
    sport: "Sport",
    tappara: "Tappara",
    tps: "TPS",
    salzburg: "Salzburg",
    "fribourg-gotteron": "Fribourg-Gottéron",
    "stavanger-oilers": "Stavanger Oilers",
    "dynamo-pardubice": "Dynamo Pardubice",
    "hc-bolzano": "HC Bolzano",
    "rouen-dragons": "Rouen Dragons",
};
const teams = Object.entries(teamNameMap).reduce((teams, [teamId, teamName]) => (Object.assign(Object.assign({}, teams), { [teamId]: {
        id: teamId,
        name: teamName,
        logoUrl: `/images/team-logos/${teamId}.png`,
    } })), {});
/**
 * Get team information for provided team ID.
 * @param id Team ID (lower-case and without scandinavian characters)
 */
export function getTeam(id) {
    var _a;
    return (_a = teams[id]) !== null && _a !== void 0 ? _a : null;
}
export function getRandomOpponentTeam() {
    return getTeam(getRandomItem(Object.keys(teams).filter((team) => team !== "ilves")));
}
