<script lang="ts">
  import Button from "@src/components/Button.svelte";
  import Form from "@src/components/Form.svelte";
  import InputLabel from "@src/components/InputLabel.svelte";
  import PageWrapper from "@src/components/PageWrapper.svelte";
  import Row from "@src/components/Row.svelte";
  import Skeleton from "@src/components/Skeleton.svelte";
  import TextInput from "@src/components/TextInput.svelte";
  import type { TripSignup } from "@src/store/signups";
  import type { PublicTrip } from "@src/store/trips";
  import { getPublicTrip, getTripTitle } from "@src/store/trips";
  import { errorToast } from "@src/utils/toast";
  import axios from "axios";
  import { Link } from "svelte-routing";

  export let slug: string = null;

  const maxSignups = 10;
  let trip: PublicTrip = null;
  let loadingStatus: "loading" | "error" | "complete" = "loading";
  let signupComplete = false;
  let signupCompleteError = false;

  let signups: Partial<TripSignup>[] = [
    { name: "", phone: "", email: "", info: "", membershipId: null },
  ];

  $: getPublicTrip(slug)
    .catch((error) => {
      loadingStatus = "error";
      return errorToast(
        "Virhe haettaessa matkan tietoja. Ole hyvä ja yritä ladata sivu uudestaan."
      )(error);
    })
    .then((t) => {
      trip = t;
      loadingStatus = "complete";
    });

  $: tripTitle = getTripTitle(trip, true);
  $: signupOpen =
    trip &&
    trip.signupStatus === "open" &&
    ["published", "published_unlisted"].includes(trip.status);
</script>

<svelte:head>
  <title>{!tripTitle ? "Matka" : tripTitle}</title>
</svelte:head>
<PageWrapper fillerColor="gray">
  <Row color="green">
    <div class="wrapper">
      {#if loadingStatus === "loading"}
        <Skeleton />
      {:else if loadingStatus === "error"}
        <Skeleton state="error" />
      {:else if !trip}
        <h1>Matkaa ei löytynyt</h1>
        <p>
          Matkaa ei löytynyt tai sitä ei olla vielä julkaistu. Ole hyvä ja
          tarkista osoite tai selaa matkoja <Link to="/matkat"
            >Matkat-sivulta</Link
          >.
        </p>
      {:else if !signupComplete}
        <h1>
          {tripTitle}
        </h1>
        <p>{@html trip.description}</p>
        {#if signupOpen}
          <div class="form">
            <Form
              useCaptcha
              onSubmit={async (captchaToken) => {
                try {
                  await axios.post(`/api/public-trips/${slug}/signup`, {
                    captchaToken,
                    signups,
                  });
                  signupComplete = true;
                } catch (error) {
                  const errorType = error?.response?.data.error;
                  if (errorType === "unexpected_error") {
                    // Terminate signup flow on unexpected errors
                    signupComplete = true;
                    signupCompleteError = true;
                    return;
                  }
                  errorToast(
                    errorType === "trip_closed"
                      ? "Ilmoittautuminen epäonnistui, koska matkalle ilmoittautuminen on suljettu."
                      : errorType === "trip_cancelled"
                        ? "Ilmoittautuminen epäonnistui, koska matka on peruttu."
                        : errorType === "trip_not_published"
                          ? "Ilmoittautuminen epäonnistui, koska matkaa ei olla julkaistu."
                          : errorType === "trip_sold_out"
                            ? "Ilmoittautuminen epäonnistui, koska matka on loppuunmyyty."
                            : errorType === "not_enough_capacity"
                              ? "Ilmoittautuminen epäonnistui, koska matkalle ei mahdu kaikki ilmoittamasi henkilöt. Poista henkilöitä ja yritä uudestaan."
                              : "Ilmoittautumisen lähettämisessä tapahtui virhe. Yritä myöhemmin uudestaan."
                  )(error);
                }
              }}
            >
              <fieldset>
                <legend>Omat tietosi</legend>
                <TextInput
                  required
                  id="name"
                  label="Nimi"
                  placeholder="Nimi"
                  bind:value={signups[0].name}
                  maxlength={100}
                />
                <TextInput
                  required
                  id="phone"
                  label="Puhelinnumero"
                  placeholder="Puhelinnumero"
                  bind:value={signups[0].phone}
                  maxlength={100}
                />
                <TextInput
                  required
                  type="email"
                  id="email"
                  label="Sähköposti"
                  placeholder="Sähköposti"
                  bind:value={signups[0].email}
                  maxlength={100}
                />

                <TextInput
                  id="membership-id"
                  label="Jäsennumero (jos olet jo jäsen)"
                  placeholder="Jäsennumero"
                  type="number"
                  bind:value={signups[0].membershipId}
                  maxlength={10}
                />
                <InputLabel id="signup-info">Lisätietoa</InputLabel>
                <textarea
                  type="text"
                  id="signup-info"
                  bind:value={signups[0].info}
                />
              </fieldset>
              {#each signups.slice(1) as signup, index}
                <fieldset>
                  <legend>Henkilö #{index + 2}</legend>
                  <TextInput
                    required
                    id="name"
                    label="Nimi"
                    placeholder="Nimi"
                    bind:value={signup.name}
                    maxlength={100}
                  />
                  <TextInput
                    id="membership-id"
                    label="Jäsennumero (jos olet jo jäsen)"
                    placeholder="Jäsennumero"
                    type="number"
                    bind:value={signup.membershipId}
                    maxlength={10}
                  />
                  <InputLabel id="signup-info">Lisätietoa</InputLabel>
                  <textarea
                    type="text"
                    id="signup-info"
                    bind:value={signup.info}
                  />
                  <div class="actions">
                    <Button
                      variant="destructive"
                      label="Poista henkilö"
                      on:click={() => {
                        signups = signups.filter((_, i) => i !== index + 1);
                      }}
                    />
                  </div>
                </fieldset>
              {/each}
              {#if signups.length < maxSignups}
                <Button
                  label="Lisää henkilö"
                  variant="secondary"
                  on:click={() => {
                    signups = [
                      ...signups,
                      { name: "", membershipId: null, info: "" },
                    ];
                  }}
                />
                <p>
                  Voit ilmoittaa enintään {maxSignups} henkilöä kerrallaan. Pyrimme
                  aina sijoittamaan samalla kerralla ilmoitetut samaan bussiin.
                </p>
              {/if}
              <p>
                Ilmoittautumalla matkalle sitoudut noudattamaan <a
                  href="/matkasaannot"
                  target="_blank"
                  rel="noopener noreferrer">matkasääntöjä</a
                >.
              </p>
            </Form>
          </div>
        {:else if trip.status === "cancelled"}
          <p>Matka on peruttu.</p>
        {:else if trip.signupStatus === "closed"}
          <p>Matkalle ilmoittautuminen on suljettu.</p>
        {:else if trip.signupStatus === "sold_out"}
          <p>Matka on loppuunmyyty.</p>
        {/if}
      {:else if !signupCompleteError}
        <h1>Kiitos ilmoittautumisesta!</h1>
        <p>
          Ilmoittautumisesi on nyt vastaanotettu, ja saat ilmoittautumisen
          tiedot sähköpostiosoitteeseen <em>{signups[0].email}</em>. Jos
          sähköposti ei tule tunnin kuluessa, tarkistathan myös
          roskapostikansion.
        </p>
        <p>
          Jos huomaat ilmoittautumisen tiedoissa virheitä tai et saa vahvistusta
          sähköpostitse, otathan meihin yhteyttä sähköpostitse osoitteeseen
          <a href="mailto:osasto41@osasto41.com">osasto41@osasto41.com</a>.
        </p>
      {:else}
        <h1>Virhe ilmoittautumisessa</h1>
        <p>
          Ilmoittautuminen on valitettavasti toistaiseksi pois käytöstä. Ole
          hyvä ja yritä myöhemmin uudestaan.
        </p>
        <p>
          Jos virhe toistuu, otathan meihin yhteyttä sähköpostitse osoitteeseen
          <a href="mailto:osasto41@osasto41.com">osasto41@osasto41.com</a>.
        </p>
      {/if}
    </div>
  </Row>
  <Row color="gray" filler />
</PageWrapper>

<style>
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 40rem;
    min-height: 20rem;
  }

  .form {
    max-width: 30rem;
  }

  fieldset {
    background: rgba(0, 0, 0, 0.3);
    padding: 1rem;
    padding-top: 2rem;
    margin-bottom: 2rem;
    width: 100%;
    max-width: 40rem;
    box-sizing: border-box;
  }

  fieldset :global(button) {
    margin-top: 2rem;
  }
</style>
