import { asyncCache } from "@src/utils/store";
import { getTeam } from "@src/utils/teams";
import axios from "axios";
import { format, parseISO } from "date-fns/esm";
function deserializeTrip(trip) {
    return Object.assign(Object.assign({}, trip), { date: !trip.date ? null : parseISO(trip.date), signupStart: !trip.signupStart ? null : parseISO(trip.signupStart), signupEnd: !trip.signupEnd ? null : parseISO(trip.signupEnd) });
}
function deserializePublicTrip(trip) {
    return Object.assign(Object.assign({}, trip), { date: !trip.date ? null : parseISO(trip.date) });
}
/**
 * Gets pinned public trips from the server.
 */
export const { getter: getPinnedPublicTrips } = asyncCache(async () => {
    const { data: trips } = await axios.get("/api/public-trips/pinned");
    return trips.map(deserializePublicTrip);
});
/**
 * Gets public trips from the server.
 */
export const { getter: getPublicTrips, store: publicTrips } = asyncCache(async () => {
    const { data: trips } = await axios.get("/api/public-trips");
    return trips.map(deserializePublicTrip);
});
/**
 * Gets a single public trip from the server.
 * @param slug Trip slug
 * @returns Trip
 */
export async function getPublicTrip(slug) {
    var _a;
    try {
        const { data: trip } = await axios.get(`/api/public-trips/${slug}`);
        return deserializePublicTrip(trip);
    }
    catch (error) {
        if (((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 404) {
            return null;
        }
        throw error;
    }
}
/**
 * Gets all trips from the server.
 */
export async function getTrips() {
    var _a, _b, _c;
    try {
        const { data: trips } = await axios.get("/api/trips");
        return trips.map(deserializeTrip);
    }
    catch (error) {
        throw (_c = (_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error) !== null && _c !== void 0 ? _c : error.message;
    }
}
/**
 * Creates a new trip.
 * @param trip
 */
export async function createTrip(trip) {
    var _a, _b, _c;
    try {
        await axios.post("/api/trips", Object.assign(Object.assign({}, trip), { 
            // Date is made timezone safe here
            date: !trip.date ? null : format(trip.date, "yyyy-MM-dd") }));
    }
    catch (error) {
        throw (_c = (_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error) !== null && _c !== void 0 ? _c : error.message;
    }
}
/**
 * Gets a single trip from the server.
 * @param slug
 * @returns Trip
 */
export async function getTrip(slug) {
    var _a, _b, _c;
    try {
        const { data: trip } = await axios.get(`/api/trips/${slug}`);
        return deserializeTrip(trip);
    }
    catch (error) {
        throw (_c = (_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error) !== null && _c !== void 0 ? _c : error.message;
    }
}
/**
 * Updates an existing trip.
 * @param slug Slug of the trip
 * @param trip Updated trip
 */
export async function updateTrip(slug, trip) {
    var _a, _b, _c;
    try {
        const { data: updatedTrip } = await axios.post(`/api/trips/${slug}/update`, Object.assign(Object.assign({}, trip), { 
            // Date is made timezone safe here
            date: !trip.date ? null : format(trip.date, "yyyy-MM-dd") }));
        return deserializeTrip(updatedTrip);
    }
    catch (error) {
        throw (_c = (_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error) !== null && _c !== void 0 ? _c : error.message;
    }
}
/**
 * Deletes a trip.
 * @param slug Slug of the trip
 */
export async function deleteTrip(slug) {
    var _a, _b, _c;
    try {
        await axios.post(`/api/trips/${slug}/delete`);
    }
    catch (error) {
        throw (_c = (_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error) !== null && _c !== void 0 ? _c : error.message;
    }
}
/**
 * Helper function for generating trip title
 * @param trip Trip
 * @param includeDate Should the date be included in the title?
 * @returns Trip title
 */
export function getTripTitle(trip, includeDate = false) {
    var _a, _b, _c, _d;
    if (!trip) {
        return null;
    }
    if (trip.title) {
        return trip.title;
    }
    const homeTeam = (_b = (_a = getTeam(trip.homeTeamId)) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : trip.homeTeamId;
    const awayTeam = (_d = (_c = getTeam(trip.awayTeamId)) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : trip.awayTeamId;
    const date = !includeDate || !trip.date ? null : format(trip.date, "dd.MM.yyyy");
    return `${trip.type} ${homeTeam} - ${awayTeam}${date ? ` ${date}` : ""}`;
}
