<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { Link } from "svelte-routing";
  import ExternalLinkIcon from "../icons/ExternalLinkIcon.svelte";

  export let to = "";
  export let isExternal = false;

  const dispatch = createEventDispatcher();

  function getProps({
    location,
    href,
    isPartiallyCurrent,
    isCurrent,
  }: {
    location: string;
    href: string;
    isPartiallyCurrent: boolean;
    isCurrent: boolean;
  }) {
    const isActive = href === "/" ? isCurrent : isPartiallyCurrent || isCurrent;

    // The object returned here is spread on the anchor element's attributes
    if (isActive) {
      return { class: "active" };
    }
    return {};
  }
</script>

<div class="wrapper" on:click={() => dispatch("click")}>
  {#if isExternal}
    <a href={to} target="_blank" rel="noopener noreferrer"
      ><slot />
      <div class="icon"><ExternalLinkIcon /></div></a
    >
  {:else}
    <Link {to} {getProps}>
      <slot />
    </Link>
  {/if}
</div>

<style>
  .wrapper {
    width: 100%;
    height: 100%;
  }

  .wrapper :global(a) {
    color: var(--text-color);
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    box-sizing: border-box;
    font-weight: normal;
  }

  .wrapper :global(.active) {
    color: var(--brighter-green);
  }

  .icon {
    display: flex;
    justify-content: center;
    margin-left: 12px;
  }
</style>
