/**
 * TODO
 * Rethink how to manage seasons.
 * https://github.com/jlaamanen/osasto41/issues/24
 */
import { get, writable } from "svelte/store";
/**
 * Helper wrapper to cache an async function into Svelte store.
 * If the async function has been resolved, returns the cached value.
 *
 * Use destructuring to get access to both wrapped async function and store:
 *   const { store: exampleStore, getter: getExampleStuff } = asyncCache(async () => {...})
 * @param func Initial async function to be cached
 */
export function asyncCache(func) {
    const store = writable(null);
    let previousArgs;
    const getter = async (...args) => {
        previousArgs = args;
        if (get(store) !== null) {
            return get(store);
        }
        const result = await func(...args);
        store.set(result);
        return result;
    };
    return {
        store,
        getter,
        async refresh() {
            // Only try to refresh if there is data - otherwise there's nothing to refresh, and getter
            // call is required.
            if (get(store) !== null) {
                store.set(null);
                await getter(...previousArgs);
            }
        },
    };
}
