<script lang="ts">
  export let state: "loading" | "error" = "loading";
</script>

<div class={state}>
  {#if state === "error"}<span>Virhe</span>{/if}
</div>

<style>
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.3;
    flex-grow: 1;
  }

  .loading {
    animation: pulse 1s infinite ease-in;
  }

  .error span {
    font-size: 2rem;
  }
</style>
