<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { backOut } from "svelte/easing";
  import { fade, fly } from "svelte/transition";

  export let level: "error" | "warning" | "success" | "info";
  export let message: string;

  const dispatch = createEventDispatcher();
</script>

<style>
  div {
    width: 80%;
    max-width: calc(0.7 * var(--content-width));
    margin-bottom: 1em;
    text-align: center;
    padding: 1em;
    color: var(--white);
    fill: var(--white);
    cursor: pointer;
    box-shadow: 0 0.1rem 0.5rem #000;
  }

  div:active {
    transform: translateY(2px);
  }

  .error {
    background: var(--red);
  }

  .warning {
    background: var(--yellow);
    color: #000;
    fill: #000;
  }

  .success {
    background: var(--brighter-green);
    color: #000;
    fill: #000;
  }

  .info {
    background: var(--blue);
  }
</style>

<div
  class={level}
  on:click={() => dispatch('close')}
  in:fly={{ delay: 0, duration: 300, x: 0, y: -50, opacity: 0.1, easing: backOut }}
  out:fade={{ duration: 300 }}>
  {message}
</div>
