<script lang="ts">
  export let required = false;
  export let id: string = null;
</script>

<label for={id}
  ><slot />{#if required}<span class="asterisk">*</span>{/if}</label
>

<style>
  label {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }

  .asterisk {
    margin-left: 0.5rem;
    color: var(--brighter-green);
  }
</style>
