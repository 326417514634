<script lang="ts">
  import Button from "@src/components/Button.svelte";
  import type { Trip } from "@src/store/trips";
  import { getTeam } from "@src/utils/teams";
  import { format } from "date-fns";
  import { Link } from "svelte-routing";
  import { fade } from "svelte/transition";

  export let trip: Trip = null;

  $: homeTeam = !trip ? null : getTeam(trip.homeTeamId);
  $: awayTeam = !trip ? null : getTeam(trip.awayTeamId);
</script>

<div class="card" in:fade>
  {#if !trip}
    <h2 class="empty">Ei avoimia matkoja</h2>
    <p>
      Katso kaikki suunnitellut matkat
      <Link to="/matkat">Matkat-sivulta</Link>
    </p>
  {:else if trip.title}
    <h3>{trip.title}</h3>
    {#if trip.date}
      <div class="date">{format(trip.date, "dd.MM.yyyy")}</div>
    {/if}
    <Link to={`/matkat/${trip.slug}`}>
      <Button variant="secondary" label="Lue lisää..." />
    </Link>
  {:else}
    <h3>{trip.type}</h3>
    <div class="teams">
      <img src={homeTeam.logoUrl} alt="{homeTeam.name} logo" />
      <span>{homeTeam.name} - {awayTeam.name}</span>
      <img src={awayTeam.logoUrl} alt="{awayTeam.name} logo" />
    </div>
    <div class="date">{format(trip.date, "dd.MM.yyyy")}</div>
    <Link to={`/matkat/${trip.slug}`}>
      <Button variant="secondary" label="Lue lisää..." />
    </Link>
  {/if}
</div>

<style>
  h3 {
    font-size: 1.5rem;
    text-transform: uppercase;
    margin-top: 0;
  }

  .card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.3);
    margin-bottom: 3rem;
    text-align: center;
    min-height: 20rem;
    width: 100%;
    padding: 2rem 1rem;
    box-sizing: border-box;
  }

  /* Ugly hack to remove the bottom margin from last card, wish 'gap' was supported more widely... */
  .card:last-child {
    margin-bottom: 0;
  }

  .card > div {
    margin: 1.8rem 0;
  }

  .teams {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .teams span {
    font-size: min(8vw, 2.5rem);
    flex-grow: 1;
    margin: 0 1rem;
  }

  .teams img {
    width: 3.5rem;
    flex-grow: 0;
  }

  .date {
    font-size: 1.5rem;
  }

  .empty {
    margin-top: 0;
  }
</style>
