<script lang="ts">
  export let label: string;
  export let variant: "primary" | "secondary" | "destructive" = "primary";
  export let disabled = false;
  export let type: "button" | "submit" | "reset" = "button";
</script>

<button {type} class={variant} on:click {disabled}>{label}</button>

<style>
  * {
    cursor: pointer;
    border: none;
    border-radius: 0.7em;
    padding: 0.6em;
    color: #000;
    min-width: 5rem;
  }

  *:hover {
    opacity: 0.8;
  }

  *:active {
    opacity: 0.6;
  }

  .primary,
  .primary:active {
    background-color: var(--brighter-green);
  }

  .secondary,
  .secondary:active {
    background-color: var(--white);
    border: 1px solid var(--green);
  }

  .destructive,
  .destructive:active {
    background-color: var(--red);
    color: #fff;
  }

  *:disabled {
    background-color: #aaa;
    color: #444;
    pointer-events: none;
  }
</style>
