<script lang="ts">
  import Skeleton from "@src/components/Skeleton.svelte";
  import { getPinnedPublicTrips } from "@src/store/trips";
  import { errorToast } from "@src/utils/toast";
  import PinnedTripCard from "./PinnedTripCard.svelte";

  let pinnedTrips = getPinnedPublicTrips().catch(
    errorToast(
      "Virhe haettaessa seuraavia matkoja. Yritä ladata sivu uudestaan."
    )
  );
</script>

<div class="wrapper">
  {#await pinnedTrips}
    <Skeleton />
  {:then trips}
    {#if !trips.length}
      <PinnedTripCard />
    {:else}
      {#each trips as trip}
        <PinnedTripCard {trip} />
      {/each}
    {/if}
  {:catch}
    <Skeleton state="error" />
  {/await}
</div>

<style>
  .wrapper {
    display: flex;
    flex-direction: column;
    max-width: 30rem;
    width: 100%;
    box-sizing: border-box;
    min-height: 23rem;
  }
</style>
