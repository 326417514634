/**
 * Wrap an error handler to display an error toast and rethrow the error.
 * @param message
 */
export function errorToast(message) {
    return (error) => {
        showToast({
            level: "error",
            message,
        });
        throw error;
    };
}
export function showToast(toast) {
    // TODO: how to deal with this window typing issue??
    window.pushToast(toast);
}
