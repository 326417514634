<script lang="ts">
  export let color: "green" | "gray" | "black";
  export let filler = false;
  export let textured = color !== "black";
  export let nopadding = false;

  $: backgroundColor =
    color === "green"
      ? "var(--row-background-green)"
      : color === "gray"
      ? "var(--row-background-gray)"
      : color;
</script>

<div
  class:filler
  class:textured
  class:nopadding
  class="container"
  style="--background-color: {backgroundColor}"
>
  <aside />
  <section>
    <slot />
  </section>
  <aside />
</div>

<style>
  .container {
    display: flex;
    background: var(--background-color);
  }

  .container.textured {
    /* TODO bundle background image */
    background-image: url("https://www.transparenttextures.com/patterns/black-felt.png");
  }

  aside {
    flex-grow: 1;
    width: 0;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.3);
    pointer-events: none;
  }

  section {
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100vw;
    width: var(--content-width);
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0 1rem;
    padding-bottom: 3rem;
    padding-top: 1rem;
  }

  .filler {
    flex-grow: 1;
    height: 0;
    overflow: hidden;
    padding: 0;
  }

  .nopadding section {
    padding: 0;
  }
</style>
