import { asyncCache } from "@src/utils/store";
import axios from "axios";
import { parseISO } from "date-fns";
import { get } from "svelte/store";
export const { store: newsStore, getter: getNews, refresh: refreshNews, } = asyncCache(async (limit) => {
    return await requestNewsArticles({
        limit,
    });
});
export async function fetchOlderNewsArticles({ limit, before, }) {
    const oldArticles = get(newsStore);
    const response = await requestNewsArticles({ before, limit });
    newsStore.set({
        articles: [...oldArticles.articles, ...response.articles],
        hasMore: response.hasMore,
    });
}
async function requestNewsArticles({ before, limit, }) {
    const { data: response } = await axios.get("/api/news", {
        params: {
            before: !before ? null : before.toISOString(),
            limit,
        },
    });
    return Object.assign(Object.assign({}, response), { articles: response.articles.map((article) => (Object.assign(Object.assign({}, article), { created: parseISO(article.created) }))) });
}
export async function postNewArticle(article) {
    await axios.post("/api/news", article);
}
export async function getNewsArticle(id) {
    const { data: article } = await axios.get(`/api/news/${id}`);
    return Object.assign(Object.assign({}, article), { created: parseISO(article.created) });
}
export async function updateNewsArticle(article) {
    await axios.post(`/api/news/update/${article.id}`, article);
}
export async function deleteNewsArticle(article) {
    await axios.post(`/api/news/delete/${article.id}`);
}
