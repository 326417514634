<script lang="ts">
  import Form from "@src/components/Form.svelte";
  import PageWrapper from "@src/components/PageWrapper.svelte";
  import Row from "@src/components/Row.svelte";
  import TextInput from "@src/components/TextInput.svelte";
  import { errorToast } from "@src/utils/toast";
  import axios from "axios";

  let name = "";
  let email = "";
  let homeTown = "";
  let membershipId = "";
  let applicationSent = false;
</script>

<svelte:head>
  <title>Osasto 41 - Jäseneksi</title>
</svelte:head>
<PageWrapper fillerColor="gray">
  {#if !applicationSent}
    <Row color="green">
      <h1>Liity jäseneksi</h1>
      <p>
        Voit liittyä jäseneksi tämän lomakkeen avulla. Osasto 41 Kannattajat
        ry:n jäsenmaksu on uusille jäsenille <strong>20 euroa</strong> ja viime
        kauden jäsenille <strong>15 euroa</strong>. Jäsenyys on voimassa kauden
        kerrallaan. Tietoja tarvitaan yhdistyksen jäsenrekisteriä varten, ja
        kaikki kentät ovat pakollisia. Tietoja ei luovuteta eteenpäin.
      </p>
      <p>
        Jäsenyys oikeuttaa alennuksiin ainakin Osaston järjestämillä
        kannattajamatkoilla. Jäsenenä tuet myös Ilveksen kannattajakulttuurin
        kehitystä!
      </p>
      <p>
        Hakemukset tarkistetaan käsin. Kun hakemus on käsitelty, jäsenyyden
        vahvistaminen sekä maksutiedot lähetetään sähköpostitse. Myös
        jäsenkortti toimitetaan PDF-tiedostona sähköpostitse.
      </p>
      <p>
        Kun sinulla on voimassa oleva jäsenyys ja jäsennumero, voit tilata
        kausikortin kannattajakatsomoon etuhintaan. <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSdrX9oMZED7jWnQqY2ZA2Y5zLP5CyOwjyZD1utSk6sxOEwA5A/viewform"
          target="_blank"
          rel="noopener noreferrer"
          >Siirry kausikorttitilauslomakkeeseen tästä</a
        >.
      </p>
      <div class="form">
        <Form
          useCaptcha
          onSubmit={async (captchaToken) => {
            try {
              await axios.post("/api/membership-applications", {
                captchaToken,
                form: {
                  name,
                  email,
                  homeTown,
                  membershipId,
                },
              });
              applicationSent = true;
            } catch (error) {
              errorToast(
                "Hakemuksen lähettämisessä tapahtui virhe. Yritä myöhemmin uudestaan."
              )(error);
            }
          }}
        >
          <TextInput
            label="Nimi"
            placeholder="Nimi"
            required
            bind:value={name}
            maxlength={100}
          />
          <TextInput
            label="Sähköposti"
            placeholder="Sähköposti"
            type="email"
            required
            bind:value={email}
            maxlength={100}
          />
          <TextInput
            label="Asuinpaikkakunta"
            placeholder="Asuinpaikkakunta"
            required
            bind:value={homeTown}
            maxlength={100}
          />
          <TextInput
            label="Jäsennumero (jos olet jo jäsen)"
            placeholder="Jäsennumero"
            type="number"
            bind:value={membershipId}
            maxlength={10}
          />
        </Form>
      </div>
    </Row>
  {:else}
    <Row color="green">
      <h1>Hakemus vastaanotettu</h1>
      <p>Kiitos, kun hait Osasto 41 jäsenyyttä!</p>
      <p>
        Hakemuksesi on nyt vastaanotettu, ja se tarkistetaan käsin. Kun hakemus
        on käsitelty, jäsenyyden vahvistaminen sekä maksutiedot lähetetään
        sähköpostitse. Myös jäsenkortti toimitetaan PDF-tiedostona
        sähköpostitse.
      </p>
      <p>
        Jos sinulla on jotain kysyttävää jäsenyyteen liittyen, otathan meihin
        yhteyttä sähköpostitse osoitteeseen
        <a href="mailto:osasto41@osasto41.com">osasto41@osasto41.com</a>.
      </p>
      <p>
        Kun sinulla on voimassa oleva jäsenyys ja jäsennumero, voit tilata
        kausikortin kannattajakatsomoon etuhintaan. <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSdrX9oMZED7jWnQqY2ZA2Y5zLP5CyOwjyZD1utSk6sxOEwA5A/viewform"
          target="_blank"
          rel="noopener noreferrer"
          >Siirry kausikorttitilauslomakkeeseen tästä</a
        >.
      </p>
    </Row>
  {/if}
  <Row color="gray" filler />
</PageWrapper>

<style>
  p {
    width: 100%;
  }
  p:last-of-type {
    margin-bottom: 2rem;
  }

  div.form {
    max-width: 30rem;
  }
</style>
