<script lang="ts">
  import { createEventDispatcher, onDestroy, onMount } from "svelte";
  import Button from "./Button.svelte";

  export let useCaptcha = false;
  export let actions: { [action in "submit" | "reset" | "delete"]?: string } = {
    submit: "Lähetä",
    reset: "Tyhjennä",
    delete: null as string,
  };
  export let onSubmit: (captchaToken?: string) => Promise<void>;
  // Load until captcha gets fully initialized
  export let loading = useCaptcha;

  // Public recaptcha site key
  const captchaSiteKey = "6Lclm_caAAAAAMX-gAtgz2B6hqVDQnK4fgMZc_8e";
  const dispatch = createEventDispatcher();

  /**
   * Callback function for recaptcha verification.
   * Token received should also be verified server-side.
   * @param token
   */
  async function verifyCaptcha(token: string) {
    // Invoke onSubmit callback - ignore errors here
    try {
      await onSubmit(token);
    } catch (error) {
      console.error(error);
    }
    loading = false;
    // Reset captcha after handling form submit
    try {
      (window as any).grecaptcha.reset();
    } catch (error) {
      // Fail silently - captcha element has probably been removed from DOM
    }
  }

  // Attach the captcha callback to global window object
  onMount(async () => {
    if (!useCaptcha) {
      return;
    }
    (window as any).verifyCaptcha = verifyCaptcha;
    // TODO: there are probably better ways to handle this, but goes for now
    // Initialize captcha by polling when it gets defined in window
    const captchaInitInterval = setInterval(() => {
      if ((window as any).grecaptcha) {
        (window as any).grecaptcha.ready(() => {
          (window as any).grecaptcha.render("form-recaptcha");
        });
        clearInterval(captchaInitInterval);
        loading = false;
      }
    }, 500);
  });

  onDestroy(() => {
    (window as any).verifyCaptcha = null;
  });

  /**
   * Override form submit by executing captcha manually.
   */
  function handleSubmit() {
    if (useCaptcha) {
      loading = true;
      (window as any).grecaptcha.execute();
    } else {
      onSubmit();
    }
  }
</script>

<form on:submit|preventDefault={handleSubmit} disabled={loading}>
  <fieldset disabled={loading}>
    <slot />
    <!-- Invisible recaptcha element -->
    {#if useCaptcha}
      <div
        id="form-recaptcha"
        data-sitekey={captchaSiteKey}
        data-callback="verifyCaptcha"
        data-size="invisible"
      />
    {/if}
    <div class="actions">
      <Button type="submit" label={actions.submit} variant="primary" />
      {#if actions.reset}
        <Button type="reset" label={actions.reset} variant="secondary" />
      {/if}
      {#if actions.delete}
        <Button
          on:click={() => {
            dispatch("delete");
          }}
          label={actions.delete}
          variant="destructive"
        />
      {/if}
    </div>
    {#if useCaptcha}
      <!-- Recaptcha badge is hidden - thus, this text is required -->
      <p class="recaptcha-info">
        Lomake on suojattu botteja vastaan reCAPTCHA:lla. Googlen
        <a href="https://policies.google.com/privacy">tietosuojakäytäntö</a> ja
        <a href="https://policies.google.com/terms">käyttöehdot</a> ovat voimassa.
      </p>
    {/if}
  </fieldset>
</form>

<style>
  form {
    width: 100%;
  }

  form[disabled="true"] {
    animation: pulse 1s infinite ease-in;
  }

  form :global(input) {
    width: 100%;
  }

  .actions {
    margin-top: 2rem;
  }

  .actions > :global(*) {
    margin-right: 0.5rem;
  }

  p.recaptcha-info {
    font-size: 0.8rem;
  }
</style>
